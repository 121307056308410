module services {
    export module logistics {
        export class commercialInvoiceNationalMeasureCodeService implements interfaces.logistics.ICommercialInvoiceNationalMeasureCodeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            remove(cnmId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoiceNationalMeasureCode/Delete", {
                    cnmId: cnmId
                });
            }
        }
    }
    angular.module("app").service("commercialInvoiceNationalMeasureCodeService", services.logistics.commercialInvoiceNationalMeasureCodeService);
}